<template>
  <h1>На жаль, сторінка, яку ви шукаєте, не існує.</h1>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>

</style>
